import React from "react";
import { Helmet } from 'react-helmet';
import { graphql } from "gatsby";
import { createLocaleTextGetter } from "../../utils";

import '../../styles/pages/obligations.scss';

const ConditionPage = ({ data, pageContext }) => {
  const localeTextGetter = createLocaleTextGetter(pageContext.locale);
  return (
    <main className="bi_obligations_wrapper">
                        <Helmet htmlAttributes={{ lang: pageContext.locale }}>
        <title>
          {pageContext.locale === "fr"
            ? "Nos conditions générales | Les Suites de l'île Barbe"
            : "Our terms and conditions | The Suites of Île Barbe"}
        </title>
        <meta
          type="description"
          content={
            pageContext.locale === "fr"
              ? "Nos conditions générales"
              : "Our terms and conditions"
          }
        />
      </Helmet>
      <h1>{localeTextGetter(data.sanityLegalDocument.title_conditions)}</h1>
      {
        data.sanityLegalDocument.content_conditions.map((condition, index) => {
          return (
            <div key={index} className="bi_obligations_item">
              <h2>{localeTextGetter(condition.title)}</h2>
              <p>{localeTextGetter(condition.content)}</p>
            </div>
          )
        })
      }
    </main>
  );
};

export default ConditionPage;

export const pageQuery = graphql`
  query ConditionPageQuery {
    sanityLegalDocument {
      title_conditions {
        fr
        en
      }
      content_conditions {
        content {
            fr
            en
          }
          title {
            fr
            en
        }
      }
    }
  }
`;
